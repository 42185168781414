import React from "react";
import { InsiderIconWithGreyBg } from "../../components/ui/icons/InsiderIconWithGreyBg";
import { Music } from "../../components/ui/icons/CategoryIcons/Music";
/**
 * @type {Link}
 * @property {string} resource_type - The type of resource, possible values are: "tag", "event", "article", "category", "external", "group", "go" and "filtered_list"
 * @property {string} [title] - The title of the link.
 * @property {string} [slug] - The slug for the link.
 * @property {string} [url] - The URL of the link (applicable for "external" resource_type).
 * @property {Object} [query_params] - Additional query parameters for the link (applicable for "filtered_list" resource_type).
 */
/**
 * @type {IIconCard}
 * @property {string} title - The title of the quick link.
 * @property {string} subtitle - The subtitle associated with the quick link.
 * @property {string} image - The URL of the image associated with the quick link.
 * @property {string[]} [includeCities] - An array of cities to include for the quick link. Add ["global"] for adding it in all cities. includeCities takes priority over excludeCities
 * @property {string[]} [excludeCities] - An array of cities to exclude for the quick link.
 * @property {Link} link - The object containing params to construct the links
 * @property {boolean} openInNewTab - Boolean set to true to open links in new tab
 */
/**
 * Configuration for the homepage UI components.
 * @type {IIconCard[]}
 */
// this is a temporary config to power the homepage ui components untill the admin flow is built
// note: if more than 3 quicklinks qualify for a city, they will be shuffled
export const quickLinks = [
    //Mumbai
    {
        title: "1 boy, 1 mystery",
        subtitle: "And a huge discovery",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1734120981/jwuchnqktljzonpfduji.jpg",
        includeCities: ["Mumbai"],
        link: {
            resource_type: "event",
            slug: "aadyam-theatre-presents-the-curious-incident-of-the-dog-in-the-nighttime-mumbai-dec21-2024"
        }
    },
    {
        title: "B-Kidzs Flea",
        subtitle: "In Mumbai, Dec 21-22",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1734121024/mcwnusjs1l2mrcbdmyif.jpg",
        includeCities: ["Mumbai"],
        link: {
            resource_type: "event",
            slug: "bkidzs-by-the-btown-fleaa-2024-dec21-2024"
        }
    },
    {
        title: "Maceo Plex",
        subtitle: "LIVE in Mumbai!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1733509182/ttfjtmt14vw7q6y4qcvw.jpg",
        includeCities: ["Mumbai"],
        link: {
            resource_type: "event",
            slug: "maceo-plex-at-nesco-mumbai-ny2025"
        }
    },
    //Delhi
    {
        title: "Coming to Delhi",
        subtitle: "Indie Music Festival",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1733509227/qiser55b7xtptr2ih1wt.jpg",
        includeCities: ["Delhi"],
        link: {
            resource_type: "event",
            slug: "indie-music-festival-jan11-2025"
        }
    },
    {
        title: "3 Legends LIVE!",
        subtitle: "Three Maestros Unite",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1732894118/lcwphlec8fpefxhwphok.jpg",
        includeCities: ["Delhi"],
        link: {
            resource_type: "event",
            slug: "triveni-3mp-delhi-dec29-2024"
        }
    },
    {
        title: "21 Savage LIVE",
        subtitle: "On Jan 31st!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1732894448/kh2lcv9uxblsjeyjup6o.jpg",
        includeCities: ["Delhi"],
        link: {
            resource_type: "event",
            slug: "indian-sneaker-festival-jan31-feb2-2025"
        }
    },
    //Bengaluru
    {
        title: "Legends LIVE",
        subtitle: " Shankar Ehsaan Loy",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731687993/pgtao0k9bjv417tldrhk.jpg",
        includeCities: ["Bengaluru"],
        link: {
            resource_type: "event",
            slug: "shankar-ehsaan-loy-live-in-concert-bangalore-2024-dec21-2024"
        }
    },
    {
        title: "Christmas Sante",
        subtitle: "In Bengaluru, Dec 22",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1732894153/ih3ww2fkzfndh10o2d4u.jpg",
        includeCities: ["Bengaluru"],
        link: {
            resource_type: "event",
            slug: "sunday-soul-sante-christmas-edition-bengaluru-dec22-2024"
        }
    },
    {
        title: "Drag Racing",
        subtitle: "In Hosur, Dec 21-22",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1734118302/fl6bczm5tkksrf1dd2zz.jpg",
        includeCities: ["Bengaluru"],
        link: {
            resource_type: "event",
            slug: "vroom-dragmeet-11th-edition-championship-dec21-2024"
        }
    },
    //Pune
    {
        title: "1 boy, 1 mystery",
        subtitle: "And a huge discovery",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1734120981/jwuchnqktljzonpfduji.jpg",
        includeCities: ["Pune"],
        link: {
            resource_type: "event",
            slug: "aadyam-theatre-presents-the-curious-incident-of-the-dog-in-the-nighttime-mumbai-dec21-2024"
        }
    },
    {
        title: "Da Vinci Live",
        subtitle: "Immersive Art Journey",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1732292229/pwzqfoo1xnlsgqfylplx.jpg",
        includeCities: ["Pune"],
        link: {
            resource_type: "event",
            slug: "da-vinci-genius-an-interactive-immersive-experience-mumbai-nov30-2024"
        }
    },
    {
        title: "Maceo Plex",
        subtitle: "LIVE in Mumbai!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1733509182/ttfjtmt14vw7q6y4qcvw.jpg",
        includeCities: ["Pune"],
        link: {
            resource_type: "event",
            slug: "maceo-plex-at-nesco-mumbai-ny2025"
        }
    },
    //Goa
    {
        title: "No EDM, No Party",
        subtitle: "Raeeth, Goa",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1727699563/qx8djyzerxqedakh6nxc.jpg",
        includeCities: ["Goa"],
        link: {
            resource_type: "event",
            slug: "raeeth-presents-experience-31-dec-dec31-2024"
        }
    },
    {
        title: "Maceo Plex",
        subtitle: "LIVE in Goa!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1734118418/wr3jivtbcin5ujyhogri.jpg",
        includeCities: ["Goa"],
        link: {
            resource_type: "event",
            slug: "pyramid-goa-dec28-2024-ny2025"
        }
    },
    {
        title: "NYE in Goa!",
        subtitle: "At Marbela Beach!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1734118441/uca1q34j0rwd3ac4kzkx.jpg",
        includeCities: ["Goa"],
        link: {
            resource_type: "event",
            slug: "marbela-beach-presents-nye-the-white-party-secret-beach-party-ny2025"
        }
    },
    //Hyderabad
    {
        title: "ICW is back!",
        subtitle: "In Hyderabad!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731736115/zh2gz0cjehegmhanmahm.jpg",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "india-cocktail-week-hyderabad-2024"
        }
    },
    {
        title: "Farhan Akhtar",
        subtitle: "LIVE in Hyderabad",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1733509758/pmxm3i4ylnhchqlcibx1.jpg",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "legacy-rewind-ft-farhan-akhtar-live-hyderabad-dec21-2024"
        }
    },
    {
        title: "Sid Sriram",
        subtitle: "LIVE in Hyderabad!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1733509654/g2mieghbtpvyxioij7bq.jpg",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "sid-sriram-live-in-concert-hyderabad-feb15-2025"
        }
    },
    //Chennai
    {
        title: "Cinema in Focus",
        subtitle: "In Chennai this Dec!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1732894362/qgotw8oojiqoxrm24gna.jpg",
        includeCities: ["Chennai"],
        link: {
            resource_type: "event",
            slug: "22nd-chennai-international-film-festival-delegate-registration-dec12-2024"
        }
    },
    {
        title: "Drag Racing",
        subtitle: "In Hosur, Dec 21-22",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1734118302/fl6bczm5tkksrf1dd2zz.jpg",
        includeCities: ["Chennai"],
        link: {
            resource_type: "event",
            slug: "vroom-dragmeet-11th-edition-championship-dec21-2024"
        }
    },
    {
        title: "December 28th",
        subtitle: "Vijay Antony LIVE",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731736133/adhms6udg2gtgn6zcpib.jpg",
        includeCities: ["Chennai"],
        link: {
            resource_type: "event",
            slug: "vijay-antony-3-live-in-concert-chennai-dec28-2024"
        }
    },
    //Kolkata
    {
        title: "Armaan Live",
        subtitle: "Pop Beats in Kolkata",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1732292824/ol2ktysnm7oueailqfqx.jpg",
        includeCities: ["Kolkata"],
        link: {
            resource_type: "event",
            slug: "armaan-malik-live-in-kolkata-dec24-2024"
        }
    },
    {
        title: "Nostalgic Vibes",
        subtitle: "Sunidhi Chauhan LIVE",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1734118275/ux25t58ck5aojozl2ulo.jpg",
        includeCities: ["Kolkata"],
        link: {
            resource_type: "event",
            slug: "sunidhis-i-am-home-i-kolkata-dec24-2024"
        }
    },
    {
        title: "It's a 'Mehfil'",
        subtitle: "Bismil Ke Mehfil ",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704307/iik0biqehnzcn18smnj7.jpg",
        includeCities: ["Kolkata"],
        link: {
            resource_type: "event",
            slug: "bismil-ke-mehfil-dec28-2024"
        }
    },
    //coimbatore
    {
        title: "Vibe with Deva",
        subtitle: "Thenisai Thendral LIVE",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731687719/iesutmc9uwtbagem4lwp.jpg",
        includeCities: ["Coimbatore"],
        link: {
            resource_type: "event",
            slug: "thenisai-thendral-deva-live-in-concert-madurai-jan18-2025"
        }
    },
    {
        title: "Cinema in Focus",
        subtitle: "In Chennai this Dec!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1732894392/cfpvxspm9rl2ozkkvxab.jpg",
        includeCities: ["Coimbatore"],
        link: {
            resource_type: "event",
            slug: "22nd-chennai-international-film-festival-delegate-registration-dec12-2024"
        }
    },
    {
        title: "December 28th",
        subtitle: "Vijay Antony LIVE",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731736133/adhms6udg2gtgn6zcpib.jpg",
        includeCities: ["Coimbatore"],
        link: {
            resource_type: "event",
            slug: "vijay-antony-3-live-in-concert-chennai-dec28-2024"
        }
    },
    //lucknow
    {
        title: "It's All Hearts",
        subtitle: "Shreya Ghoshal LIVE",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731687723/naedks0x9qkpm62rrr04.jpg",
        includeCities: ["Lucknow"],
        link: {
            resource_type: "event",
            slug: "shreya-ghoshal-all-hearts-tour-lucknow-dec21-2024"
        }
    },
    {
        title: "Farhan Akhtar",
        subtitle: "LIVE in Lucknow!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1733509758/pmxm3i4ylnhchqlcibx1.jpg",
        includeCities: ["Lucknow"],
        link: {
            resource_type: "event",
            slug: "legacy-rewind-ft-farhan-akhtar-live-lucknow-dec22-2024"
        }
    },
    {
        title: "21 Savage LIVE",
        subtitle: "On Jan 31st!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1732894448/kh2lcv9uxblsjeyjup6o.jpg",
        includeCities: ["Lucknow"],
        link: {
            resource_type: "event",
            slug: "indian-sneaker-festival-jan31-feb2-2025"
        }
    },
    //Visakhapatnam
    {
        title: "ICW is back!",
        subtitle: "In Hyderabad!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731736115/zh2gz0cjehegmhanmahm.jpg",
        includeCities: ["Visakhapatnam"],
        link: {
            resource_type: "event",
            slug: "india-cocktail-week-hyderabad-2024"
        }
    },
    {
        title: "Farhan Akhtar",
        subtitle: "LIVE in Hyderabad",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1733509758/pmxm3i4ylnhchqlcibx1.jpg",
        includeCities: ["Visakhapatnam"],
        link: {
            resource_type: "event",
            slug: "legacy-rewind-ft-farhan-akhtar-live-hyderabad-dec21-2024"
        }
    },
    {
        title: "Sid Sriram",
        subtitle: "LIVE in Hyderabad!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1733509654/g2mieghbtpvyxioij7bq.jpg",
        includeCities: ["Visakhapatnam"],
        link: {
            resource_type: "event",
            slug: "sid-sriram-live-in-concert-hyderabad-feb15-2025"
        }
    },
    //Vizag
    {
        title: "ICW is back!",
        subtitle: "In Hyderabad!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731736115/zh2gz0cjehegmhanmahm.jpg",
        includeCities: ["Vizag"],
        link: {
            resource_type: "event",
            slug: "india-cocktail-week-hyderabad-2024"
        }
    },
    {
        title: "Farhan Akhtar",
        subtitle: "LIVE in Hyderabad",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1733509758/pmxm3i4ylnhchqlcibx1.jpg",
        includeCities: ["Vizag"],
        link: {
            resource_type: "event",
            slug: "legacy-rewind-ft-farhan-akhtar-live-hyderabad-dec21-2024"
        }
    },
    {
        title: "Sid Sriram",
        subtitle: "LIVE in Hyderabad!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1733509654/g2mieghbtpvyxioij7bq.jpg",
        includeCities: ["Vizag"],
        link: {
            resource_type: "event",
            slug: "sid-sriram-live-in-concert-hyderabad-feb15-2025"
        }
    },
    //Madurai
    {
        title: "Vibe with Deva",
        subtitle: "Thenisai Thendral LIVE",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731687732/oijtq2o76zup3vwi546c.jpg",
        includeCities: ["Madurai"],
        link: {
            resource_type: "event",
            slug: "thenisai-thendral-deva-live-in-concert-madurai-jan18-2025"
        }
    },
    {
        title: "An EPIC night",
        subtitle: "Ilaiyaraaja LIVE",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1734118246/d7jhqgvqc7ogbur3jaoa.jpg",
        includeCities: ["Madurai"],
        link: {
            resource_type: "event",
            slug: "maestro-ilaiyaraaja-live-in-concert-jan17-2025"
        }
    },
    {
        title: "December 28th",
        subtitle: "Vijay Antony LIVE",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731736133/adhms6udg2gtgn6zcpib.jpg",
        includeCities: ["Madurai"],
        link: {
            resource_type: "event",
            slug: "vijay-antony-3-live-in-concert-chennai-dec28-2024"
        }
    },
];
export const placeholderQuickLinks = [
    {
        title: "Host an Event",
        subtitle: "Make your event live in under 5 mins",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "external",
            url: "/list-your-events"
        }
    },
    {
        title: "Events this weekend",
        subtitle: "Events this weekend",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "everything",
            time_filter: "weekend"
        }
    }
];
export const mobilePlaceHolderLinks = [
    {
        title: "Download our app now!",
        subtitle: "Android & iOS",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "external",
            url: "https://link.insider.in/app"
        }
    },
    ...placeholderQuickLinks
];
/**
     Key to be added for iOs on categoryBanners array:
     * hideForIOS : true if we don't want to show banner on iOs devices
     * showForIOS : true if we want to to show a different banenr for iOs device, create another object named categoryBannersForIOs with same format
     * * don't make both true
     * * No need to add above keys on categoryBannersForIOs, adding keys (if required) on original config array is sufficient.
     * * Don't add any key if not required
     */
export const categoryBannersForIOs = [];
export const categoryBanners = [
    //Category Banner
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734117595/ttgdk0fkvktthu3tisut.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734117598/wtvrqveiie43o9nwegij.jpg",
        includeCities: ["mumbai"],
        link: {
            resource_type: "event",
            slug: "artbat-live-in-mumbai-dec-24-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1731737031/ks8zhedhbu9zcy6kpwab.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1731737035/bgjvucoe14hbcytpve1p.jpg",
        includeCities: ["delhi"],
        link: {
            resource_type: "event",
            slug: "ben-bohmer-bloomlive-delhi-ncr-dec28-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732293325/gfwicpcm8z7kiwkgh4in.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732293325/my9t4tpc0egzk9r4qz7u.jpg",
        includeCities: ["bengaluru"],
        link: {
            resource_type: "event",
            slug: "peter-cat-recording-co-good-luck-beta-24-bengaluru-dec28-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734117595/ttgdk0fkvktthu3tisut.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734117598/wtvrqveiie43o9nwegij.jpg",
        includeCities: ["pune"],
        link: {
            resource_type: "event",
            slug: "artbat-live-in-mumbai-dec-24-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734117760/gu0lerbxszjhvyxv7dep.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734117759/hveiwc95ltds3xaklquj.jpg",
        includeCities: ["hyderabad"],
        link: {
            resource_type: "event",
            slug: "sid-sriram-live-in-concert-hyderabad-feb15-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734117687/wtle66e7thy83vkcgqng.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734117687/u23vnfvjsombodahzxzm.jpg",
        includeCities: ["goa"],
        link: {
            resource_type: "event",
            slug: "krank-x-salud-nye-2025-dec31-2024-ny2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732105013/kmkb6wvqgnlq9icerzfu.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732105002/hcjxm4k2ru6m41papxox.jpg",
        includeCities: ["chennai"],
        link: {
            resource_type: "event",
            slug: "thenisai-thendral-deva-live-in-concert-madurai-jan18-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734117861/zg74kx26calooowwc2co.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734117861/j8z81xhejqwdgygonmue.jpg",
        includeCities: ["kolkata"],
        link: {
            resource_type: "event",
            slug: "peter-cat-recording-co-good-luck-beta-24-kolkata-dec29-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732105013/kmkb6wvqgnlq9icerzfu.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732105002/hcjxm4k2ru6m41papxox.jpg",
        includeCities: ["coimbatore"],
        link: {
            resource_type: "event",
            slug: "thenisai-thendral-deva-live-in-concert-madurai-jan18-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734117929/h4qqs1loruenrfpyikv8.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734117928/mdaynl1smdbjhqfq5rbu.jpg",
        includeCities: ["lucknow"],
        link: {
            resource_type: "event",
            slug: "shreya-ghoshal-all-hearts-tour-lucknow-dec21-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734117760/gu0lerbxszjhvyxv7dep.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734117759/hveiwc95ltds3xaklquj.jpg",
        includeCities: ["Visakhapatnam"],
        link: {
            resource_type: "event",
            slug: "sid-sriram-live-in-concert-hyderabad-feb15-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734117760/gu0lerbxszjhvyxv7dep.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734117759/hveiwc95ltds3xaklquj.jpg",
        includeCities: ["Vizag"],
        link: {
            resource_type: "event",
            slug: "sid-sriram-live-in-concert-hyderabad-feb15-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732293385/bhduz6lxmkvjhkkdlosm.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732293384/hlkrhxhl9j1rkbrrwyzr.jpg",
        includeCities: ["madurai"],
        link: {
            resource_type: "event",
            slug: "vijay-antony-3-live-in-concert-chennai-dec28-2024"
        }
    },
];
export const showcase = [
    //Type B
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732293586/jsicdmci3arb1prbd3az.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732293585/yqtx50iaqyi0hepettmk.jpg",
        heading: "AP Dhillon - 2024 India Tour",
        subheading: "Catch  'The Brownprint' 2024 tour Live with Premium Experiences",
        caption: "AP Dhillon, center stage! The Brownprint Tour’s 360° lands in India, with a 360° experience and redefining vibes with his biggest hits.",
        cta: "Read more",
        includeCities: ["chandigarh"],
        tag: "ap-dhillon-live-the-brownprint",
        cardListType: "schedule-card",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "article",
            slug: "ap-dhillon-india-tour"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    //Type - A
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732895519/dt1rilqdxodtxknwazff.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732895520/irepy0iqdlg3uwxfj1ut.jpg",
        categoryIcon: <Music />,
        heading: "Ben Bohmer Live in Delhi",
        subheading: "Experience the magic of Ben Bohmer’s Bloom Live tour in Delhi NCR!",
        caption: "Join us for Ben Bohmer’s most anticipated live performance yet! Experience his 3rd album, Bloom, with a mesmerizing blend of electronic beats and heartfelt melodies.",
        cta: "Buy Now",
        includeCities: ["delhi"],
        link: {
            resource_type: "event",
            slug: "ben-bohmer-bloomlive-delhi-ncr-dec28-2024-ny2025"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1733510167/knvfnlv0gbxhcdxy1pyj.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1733510169/ajv9p7t84g8tjuwiijbs.jpg",
        categoryIcon: <Music />,
        heading: "An Immersive Experience",
        subheading: "The world of Leonardo da Vinci ft. projections, AI, & interactive displays.",
        caption: "Step into his world through an award-winning immersive show. Explore 50+ masterpieces, like the Mona Lisa, via projections, AI & interactive displays.",
        cta: "Buy Now",
        includeCities: ["mumbai", "pune"],
        link: {
            resource_type: "event",
            slug: "da-vinci-genius-an-interactive-immersive-experience-mumbai-nov30-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732294217/xz7txuu4we3ng5nyyhpn.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732294218/hz8tt05yinn9dqv6ekzu.jpg",
        categoryIcon: <Music />,
        heading: "Circus X Namas'cray Goa",
        subheading: "Celebrate with Ben Böhmer and 30+ artists at the ultimate Goa NYE festival!",
        caption: "Celebrate NYE in Goa with Ben Böhmer and 30+ artists at Circus X Namas'cray! 4 nights of epic performances, Funktion One sound, and a night to remember.",
        cta: "Buy Now",
        includeCities: ["goa"],
        link: {
            resource_type: "event",
            slug: "da-vinci-genius-an-interactive-immersive-experience-mumbai-nov30-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    //type C
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1725066325/noypqumqsjlgsv9ejavv.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1725066308/rq7dipibzgsrkuq5qrdp.jpg",
        categoryIcon: <Music />,
        heading: "Get ready for Sunburn!",
        subheading: "Step into the vibrant world of Sunburn Union – your favorite venue!",
        caption: "With an epic lineup of music, stunning visuals & unforgettable energy, this iconic venue is the heart of the city’s nightlife.",
        cta: "Book Now",
        includeCities: ["bengaluru"],
        cardListType: "small-event-card",
        tag: "sunburn-union",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "external",
            url: "/sunburn-union/venue"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732895519/dt1rilqdxodtxknwazff.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732895520/irepy0iqdlg3uwxfj1ut.jpg",
        categoryIcon: <Music />,
        heading: "Ben Böhmer: In Bloom",
        subheading: "Hyderabad gets ready for an epic live performance",
        caption: "Experience Ben Böhmer’s largest tour in Hyderabad with his signature sounds and musical innovation. Don’t miss this unforgettable night!",
        cta: "Buy Now",
        includeCities: ["hyderabad", "visakhapatnam", "vizag"],
        link: {
            resource_type: "event",
            slug: "ben-bohmer-bloom-live-immersive-experience-quake-hyderabad-dec29-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732895519/dt1rilqdxodtxknwazff.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732895520/irepy0iqdlg3uwxfj1ut.jpg",
        categoryIcon: <Music />,
        heading: "Ben Bohmer LIVE",
        subheading: "Experience the magic of Ben Bohmer with Anyasa & Stephan Bodzin this Dec 27!",
        caption: "Join us for an unforgettable night with Ben Bohmer in Kolkata on Dec 27 at Bisw Bangla Milan Mela. Get ready for mind-blowing melodies and an epic live performance!",
        cta: "Buy Now",
        includeCities: ["kolkata"],
        link: {
            resource_type: "event",
            slug: "ben-bohmer-bloom-live-kolkata-27dec-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1731738331/y5ysniu4l9qzdjmekppa.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1731738336/agemddfvawjhtewmar62.jpg",
        categoryIcon: <Music />,
        heading: "Vijay Antony LIVE",
        subheading: "Relive the nostalgia with him on stage!",
        caption: "Vijay Antony, the OG of Tamil rap since 2005, continues to shape the industry with timeless hits like \“Saathikadi,” \“Aathichudi,\” and “Machakanni.\"",
        cta: "Buy Now",
        includeCities: ["chennai", "coimbatore"],
        link: {
            resource_type: "event",
            slug: "vijay-antony-3-live-in-concert-chennai-dec28-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734119476/bv1avix9hfwctafsguey.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734119479/ei4jkvhpp6wc5ev2kajz.jpg",
        categoryIcon: <Music />,
        heading: "Deva Live in Madurai!",
        subheading: "Join us this January to celebrate the legend Deva with his timeless hits!",
        caption: "Madurai, get ready to vibe with Deva! Enjoy his all-time hits from Baasha, Arunachalam, and more. This January, celebrate the musical legend in his hometown!",
        cta: "Buy Now",
        includeCities: ["madurai"],
        link: {
            resource_type: "event",
            slug: "thenisai-thendral-deva-live-in-concert-madurai-jan18-2025"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    }
];
